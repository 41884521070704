<script>
import { onMounted, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import { ipqsIsPhoneValid } from '@/util/validations'
import { maska } from 'maska'

const questionText = 'Last Step! Your results are ready. Enter your mobile or home phone number.'

const stepName = 'Phone'

export default {
  name: stepName,

  directives: {
    maska
  },

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      phone: {
        value: '',
        error: false
      }
    })

    const setAnswer = async () => {
      state.phone.error = await ipqsIsPhoneValid(state.phone.value)
      if (state.phone.error) return

      const answer = {field: 'phone', value: state.phone.value.replace(/[()\-\s]+/g, "")}
      store.commit('SET_FIELD', answer)
      emit('next-step', stepName)
    }

    const handler = () => {
      state.phone.error = false
    }

    return {
      questionText,
      ...toRefs(state),
      setAnswer,
      handler
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading phone-heading">{{questionText}}</h2>
      </div>
    </div>

    <div class="row">
      <div class="box phone-box">
        <input
          type="text"
          v-maska="'(###) ### ####'"
          class="form-input phone-input"
          :class="{'phone-error' : phone.error}"
          placeholder="Phone"
          v-model="phone.value"
          @input="handler"
        >

        <p class="muted-text">By clicking the View My Results Button, I agree to the consents below the button.</p>

        <button
          class="main-button phone-button mt-sm"
          @click="setAnswer"
        >
          View My Results
        </button>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <p class="muted-text tcpa">
          By clicking the submit button, I confirm I have read and agreed to this website's <a href="/terms-of-use" target="_blank">Terms of Service</a> and
          <a href="/privacy-policy" target="_blank">Privacy Policy</a> and provide my express written consent via electronic signature authorizing
          Auto Accident Team and <a href="/partners" target="_blank">one or more law firms, their agents and marketing partners</a> to contact me about
          my case and other related legal products and services to the number and email address I provided (including any wireless number). I further
          expressly consent to receive telemarketing emails, calls, text messages, pre-recorded messages, and artificial voice messages via an
          autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state, federal or corporate
          “Do Not Call” list. I understand that my consent is not a condition of purchase of any goods or services and that standard message and
          data rates may apply. For California residents see <a href="/privacy-policy/#ca-privacy-rights" target="_blank">here</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/phone.scss';
</style>